import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private auth: AuthService) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isAuth = this.auth.isAuthenticated.value;
    // const fullPath = segments.reduce((path, currentSegment) => {
    //   return `${path}/${currentSegment.path}`;
    // }, '');
    // console.log(fullPath);
    if (!isAuth) {
      // Store the attempted URL for redirecting
      // this.auth.redirectUrl = fullPath;
      this.router.navigate(['/signin']);
    }
    return isAuth;
  }
}
