import { Injectable, TemplateRef } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(
    private notification: NzNotificationService,
    private http: HttpService
  ) { }

  showNotification(
    type: 'success' | 'warning' | 'error' = 'success',
    title: string = null,
    body: string = null
  ): void {
    this.notification.create(type, title, body, { nzPlacement: 'bottomRight' });
  }
  showOnTimeNotification(key: string,
    type: 'success' | 'warning' | 'error' = 'success',
    title: string = null,
    body: string = null,

  ): void {
    // this.notification.create(type, title, body, { nzPlacement: 'bottomRight' });
    this.notification.create(type, title, body, { nzKey: key, nzPlacement: 'bottomRight' });

  }

  showTemplateNotification(
    template: TemplateRef<{}>,
    data: { title: string; body?: string; type: string }
  ) {
    this.notification.template(template, { nzData: data, nzDuration: 3000 });
  }

  async reportError(pageName: string, errorMessage: string, customComment: string = "") {
    const body = { pageName, errorMessage, customComment };
    try {
      await this.http.request(this.http.POST, '/api/ErrorReport', body);
    } catch (error) {
      console.log({ 'reportError Error:': error });
    }
  }

  validEmail(email: string) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  getCompanyName(data1, data2) {
    return data1 + `${data2 != null ? " (" + data2 + ")" : ""}`
  }
  getISOFormatDateString = (date: Date) => {
    const year = `${date.getFullYear()}`;
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;

    return (
      year +
      '-' +
      (month.length == 1 ? `${0}${month}` : month) +
      '-' +
      (day.length == 1 ? `${0}${day}` : day)
    );
  };

  formattedDate(dateString: string, showTime: boolean = false): string {
    if (dateString == null) {
      return "";
    }
    let d = new Date(dateString + '+0000');
    if (!showTime) {
      try {
        var dateArray = d.toDateString().split(" ");
        return dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3]
        // return dateArray.join(", ");
      } catch (error) {
        d.toDateString()
      }

    } else {
      try {
        var dateArray = d.toDateString().split(" ");
        let dateString = dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3] + ', '
        let timeStringBuffer = d.toTimeString().split(' ')[0];
        var timeArray = timeStringBuffer.split(":");
        let timeString = timeArray[0] + ":" + timeArray[0];

        return dateString + this.getTAmPm(d);
      } catch (error) {
        d.toDateString()
      }
    }

    // if (!showTime) return d.toDateString();
    return d.toDateString() + ', ' + d.toTimeString().split(' ')[0];
  }
  formattedDateWithoutGMT(dateString: string, showTime: boolean = false): string {
    if (dateString == null) {
      return "";
    }
    let d = new Date(dateString);
    if (!showTime) {
      try {
        var dateArray = d.toDateString().split(" ");
        return dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3]
        // return dateArray.join(", ");
      } catch (error) {
        d.toDateString()
      }

    } else {
      try {
        var dateArray = d.toDateString().split(" ");
        let dateString = dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3] + ', '
        let timeStringBuffer = d.toTimeString().split(' ')[0];
        var timeArray = timeStringBuffer.split(":");
        let timeString = timeArray[0] + ":" + timeArray[0];

        return dateString + this.getTAmPm(d);
      } catch (error) {
        d.toDateString()
      }
    }

    // if (!showTime) return d.toDateString();
    return d.toDateString() + ', ' + d.toTimeString().split(' ')[0];
  }
  formattedTimeFromDate(dateString: string): string {
    if (dateString == null) {
      return "NA";
    }
    let d = new Date(dateString);

    try {
      // var dateArray = d.toDateString().split(" ");
      // let dateString = dateArray[1] + ' ' + dateArray[2] + ', ' + dateArray[3] + ', '
      // let timeStringBuffer = d.toTimeString().split(' ')[0];
      // var timeArray = timeStringBuffer.split(":");
      // let timeString = timeArray[0] + ":" + timeArray[0];

      return this.getTAmPm(d);
    } catch (error) {
      d.toDateString()
    }


    // if (!showTime) return d.toDateString();
    return d.toTimeString().split(' ')[0];
  }
  getTimeStr(t: string) {

    t = t.replace(/\u200E/g, '');
    t = t.replace(/^([^\d]*\d{1,2}:\d{1,2}):\d{1,2}([^\d]*)$/, '$1$2');
    var result = t;
    return result;
  }
  getTAmPm(dt: Date) {

    let normalizeHour = dt.getHours() >= 12 ? dt.getHours() - 12 : dt.getHours()
    if (dt.getHours() >= 12) {
      normalizeHour = dt.getHours() - 12;
      if (normalizeHour == 0) {
        normalizeHour = 12;
      }
    }
    return dt.getHours() >= 12 ? normalizeHour + ':' + (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes()) + ' PM' : normalizeHour + ':' + (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes()) + ' AM'

  }
  getDayDiff(dateString: string): string {
    let startDate = new Date(dateString + '+0000');

    const msInDay = 24 * 60 * 60 * 1000;
    var endDate = new Date();

    return Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInDay,
    ) + "";
  }
  timeSince(time: any): string {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = Date.parse(time + '+0000');
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now';
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == 'string') return format[list_choice];
        else
          return (
            Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
          );
      }
    return time;
  }

  timeElapsed(startTime: Date, endTime: Date) {
    // time difference in ms
    let timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
    // strip the ms
    timeDiff /= 1000;
    // get seconds (Original had 'round' which incorrectly counts 0:28, 0:29, 1:30 ... 1:59, 1:0)
    const seconds = Math.round(timeDiff % 60);
    // remove seconds from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get minutes
    const minutes = Math.round(timeDiff % 60);
    // remove minutes from the date
    timeDiff = Math.floor(timeDiff / 60);
    // get hours
    const hours = Math.round(timeDiff % 24);
    return { hours, minutes, seconds };
  }

  timeDiffCalc(dateFuture: any, dateNow: any) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    // const days = Math.floor(diffInMilliSeconds / 86400);
    // diffInMilliSeconds -= days * 86400;
    // console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    // console.log('minutes', minutes);

    let difference = '';
    // if (days > 0) {
    //   difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    // }

    difference +=
      hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} minutes`
        : `${minutes} minutes`;

    return difference;
  }
  getMonthName(month: number): string {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
      default:
        return "";
    }
  }
}
