import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  FormOutline,
  DashboardOutline,
  HomeOutline,
  CheckSquareOutline,
  ClockCircleOutline,
  ContactsOutline,
  CalendarOutline,
  AuditOutline,
  ScheduleOutline,
  FolderOutline,
  MenuOutline,
  UserOutline,
  PlusOutline,
} from '@ant-design/icons-angular/icons';

const icons = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  HomeOutline,
  CheckSquareOutline,
  ClockCircleOutline,
  ContactsOutline,
  CalendarOutline,
  AuditOutline,
  ScheduleOutline,
  FolderOutline,
  MenuOutline,
  UserOutline,
  PlusOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
