import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICreateCheckoutSessionResponse } from './models/CreateCheckoutSessionResponse.interface';
import { IOrgnizationDetails } from './models/OrganizationDetails.interface';
import { AuthService } from './services/auth.service';
import { HttpService } from './services/http.service';
import { UtilityService } from './utility/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private auth: AuthService,
    private http: HttpService,
    private util: UtilityService,
    private renderer: Renderer2,
    private readonly router: Router,
    private readonly titleService: Title
  ) { }

  ngOnInit(): void {
    console.log("App component  call");
    this.auth.autoAuthneticateUser();
    this.registerOrganizationIfPaymentSuccss();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() =>
      this.titleService.setTitle("Agility - CRM"));
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  async registerOrganizationIfPaymentSuccss() {
    const orgString = localStorage.getItem(
      environment.organizationDataStorageKey
    );
    const sessionString = localStorage.getItem(
      environment.checkoutSessionDataStorageKey
    );
    if (!orgString || !sessionString) return;

    const organization = JSON.parse(orgString) as IOrgnizationDetails;
    const session = JSON.parse(sessionString) as ICreateCheckoutSessionResponse;

    try {
      const body = {
        licenceCount: 1,
        paymentSummaryId: session.paymentSummaryId,
        organizationDetails: {
          // "id": "string",
          // "code": "string",
          name: organization.organization,
          orgCode: session.orgCode,
          // "headOfficeAddress": "string",
          // "domain": "string",
          // "createdAt": "2021-07-05T10:43:46.779Z",
          // "modifiedAt": "2021-07-05T10:43:46.780Z"
        },
        adminDetails: {
          firstName: organization.firstName,
          // "middleName": "string",
          lastName: organization.lastName,
          email: organization.email,
          password: organization.password,
        },
      };

      const response = await this.http.request(
        this.http.POST,
        this.http.registerOrganizationURL,
        body
      );
      if (response.statusCode == 200 && response.success) {
        this.util.showNotification(
          'success',
          'Payment successful',
          'Please check your email for credentials'
        );
      }
    } catch (error) {
      console.log(error);
    }

    localStorage.removeItem(environment.organizationDataStorageKey);
    localStorage.removeItem(environment.checkoutSessionDataStorageKey);
  }
}
