import { Injectable } from '@angular/core';
import { IUIElement, IUIElementLabel } from '../models/UIElement.interface';
import { HttpService } from './http.service';

const LABEL_CONFIG = 'LABEL_CONFIG';
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private _uiElements: IUIElement[] = [];

  constructor(private http: HttpService) { }

  async fetchAllData() {
    try {
      const response = await this.http.request(
        this.http.GET,
        this.http.UIElementURL
      );
      if (response?.payload) {
        console.log(response?.payload);
        const data = response?.payload as IUIElement[];
        for (let i = 0; i < data.length; i++) {
          const labels = await this.fetchLabels(data[i].id);
          data[i].labels = labels;
        }
        localStorage.setItem(LABEL_CONFIG, JSON.stringify(data));
        this._uiElements = data;
      }
      throw 'No data';
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUIElement(): Promise<IUIElement[] | null> {
    try {
      const response = await this.http.request(
        this.http.GET,
        this.http.UIElementURL
      );
      if (response?.payload) {
        console.log(response?.payload);
        const data: IUIElement[] = (
          response?.payload as { page: IUIElement; labels: IUIElementLabel[] }[]
        ).map((r) => ({ ...r.page, labels: r.labels }));
        this._uiElements = data;
        localStorage.setItem(LABEL_CONFIG, JSON.stringify(this._uiElements));
        return data;
      }
      throw 'No data';
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async createDefaultUIElements(): Promise<boolean> {
    const elements = [
      { name: 'Tasks & Events' },
      { name: 'Activities' },
      { name: 'Companies' },
      { name: 'Contacts' },
      { name: 'Matters' },
      { name: 'Appointments' },
      { name: 'Documents' },
      { name: 'Communications' },
    ];
    let failedAPICallCount = 0;
    for (const e of elements) {
      try {
        const response = await this.http.request(
          this.http.POST,
          this.http.UIElementURL,
          e
        );
        if (response?.success && response.statusCode == 200) {
          console.log(response);
          continue;
        }
        throw 'No data';
      } catch (error) {
        console.log(error);
        failedAPICallCount++;
      }
    }
    if (failedAPICallCount !== 0) {
      return false;
    }
    return true;
  }

  // label
  async fetchLabels(uiElementId: string): Promise<IUIElementLabel[]> {
    try {
      const response = await this.http.request(
        this.http.GET,
        this.http.UIElementURL + `/${uiElementId}`
      );
      if (response?.payload) {
        console.log(response?.payload);
        if (response?.payload?.labels) {
          const index = this._uiElements.findIndex((e) => e.id == uiElementId);
          if (index > -1) {
            this._uiElements[index].labels = response.payload.labels;
            localStorage.setItem(
              LABEL_CONFIG,
              JSON.stringify(this._uiElements)
            );
          }
        }
        return response?.payload?.labels || [];
      }
      throw 'No data';
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async createLabel(
    uiElementId: string,
    key: string,
    value: string
  ): Promise<IUIElementLabel | null> {
    try {
      const body = {
        uiPageID: uiElementId,
        lebelKey: key,
        lebelValue: value,
        labelDescription: '',
      };
      const response = await this.http.request<IUIElementLabel>(
        this.http.POST,
        this.http.UIElementLabelURL,
        body
      );
      if (response?.success && response?.statusCode == 200) {
        console.log(response);
        if (response?.payload) {
          const index = this._uiElements.findIndex((e) => e.id == uiElementId);
          if (index > -1) {
            this._uiElements[index].labels.push(response.payload);
            this._uiElements = this._uiElements;
            localStorage.setItem(
              LABEL_CONFIG,
              JSON.stringify(this._uiElements)
            );
          }
        }
        return response?.payload;
      }
      throw 'No data';
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateLabel(
    id: string,
    uiElementId: string,
    key: string,
    value: string
  ): Promise<IUIElementLabel | null> {
    try {
      const body = {
        id: id,
        uiPageID: uiElementId,
        lebelKey: key,
        lebelValue: value,
        labelDescription: '',
      };
      const response = await this.http.request(
        this.http.PUT,
        this.http.UIElementLabelURL,
        body
      );
      if (response?.success && response?.statusCode == 200) {
        console.log(response);
        const index = this._uiElements.findIndex((e) => e.id == uiElementId);
        if (index > -1) {
          const lIndex = this._uiElements[index].labels.findIndex(
            (l) => l.id == id
          );
          if (lIndex > -1) {
            this._uiElements[index].labels[lIndex].lebelValue = value;
            this._uiElements[index].labels[lIndex].oldlebelValue = value;
            this._uiElements = this._uiElements;
            localStorage.setItem(
              LABEL_CONFIG,
              JSON.stringify(this._uiElements)
            );
          }
        }
        let data: IUIElementLabel = {
          id: id,
          uiPageID: uiElementId,
          lebelKey: key,
          lebelValue: value,
          oldlebelValue: value
        };
        return data;
      }
      throw 'No data';
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  labelFor(
    menu:
      | 'Tasks & Events'
      | 'Activities'
      | 'Contacts'
      | 'Companies'
      | 'Matters'
      | 'Appointments'
      | 'Documents'
      | 'Communications'
      | string,
    labelKey: string
  ): string {
    if (!this._uiElements.length) {
      const d = localStorage.getItem(LABEL_CONFIG);
      if (!d) {
        return labelKey === 'Menu Name' ? menu : labelKey;
      }
      this._uiElements = JSON.parse(d);
    }
    const uiElement = this._uiElements.find((ui) => ui.name === menu);
    if (uiElement) {
      if (uiElement.labels?.length) {
        const label = uiElement.labels.find((l) => l?.lebelKey === labelKey);
        if (label) {
          return label.lebelValue;
        }
      }
    }
    return labelKey === 'Menu Name' ? menu : labelKey;
  }
}
