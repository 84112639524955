// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe_public_key:
    'pk_test_51KHTsVK9XyUaqxuTWYlMKCnp3GXRZqV2r2QN9CCUwgibs4mK6uucubHcGObXvSwosH7DFB9XuNbFU9VoDl63lE6100npPkN7dw',
  // stripe_public_key:
  //   'pk_live_51KHTsVK9XyUaqxuT8BbwuSSS7ZoFiKEiNpnIjSRiwNCi7YR2FM3IHTZAQcsHiSJ1mBJWNcPwXoAMWxgPpicEaSyT00vtZ0jdKL',
  organizationDataStorageKey: 'organization_data',
  billingFromDateDataStorageKey: 'billing_from_date_data',
  billingToDateDataStorageKey: 'billing_to_date_data',
  archieveSwitchONOrOFFKey: 'archieveSwitchONOrOFFKey',
  billingMethodIdDataStorageKey: 'billing_method_id_data',
  billingCompaniesDataStorageKey: 'billing_companies_data',

  homePageActivityPerformanceFromDateDataStorageKey: 'home_page_activity_performance_from_date_data',
  homePageActivityPerformanceToDateDataStorageKey: 'home_page_activity_performance_to_date_data',
  homePageTaskPerfFromDateDataStorageKey: 'home_page_task_perf_from_date_data',
  homePageTaskPerfToDateDataStorageKey: 'home_page_task_perf_to_date_data',
  homePageActualBudgetBMDataStorageKey: 'home_page_actual_budget_BM_data',
  homePageMatterBOTDataStorageKey: 'home_page_matter_bot_data',

  configSelectedElementStorageKey: 'config_selected_ui_element_data',

  timesheetDayWiseFromDateStorageKey: 'timesheet_day_from_date_data',
  timesheetDayWiseToDateStorageKey: 'timesheet_day_to_date_data',
  timesheetDayWiseSelectIndexStorageKey: 'timesheet_day_select_index_data',
  timesheetDayWiseSelectUserIdStorageKey: 'timesheet_day_select_userid_data',
  timesheetWeekWiseFromDateStorageKey: 'timesheet_week_from_date_data',
  timesheetWeekWiseToDateStorageKey: 'timesheet_week_to_date_data',

  documentPageProjectStorageKey: 'document_page_project_data',

  projectPageStaffDataStorageKey: 'project_page_staff_data',

  projectPageCurrentPageSizeStorageKey: 'project_page_current_page_size',
  projectPageCurrentPageNumberStorageKey: 'project_page_current_page_Number',
  projectPageCurrentTabStorageKey: 'project_page_current_tab',

  communicationFromDateStorageKey: 'communication_page_from_date_data',
  communicationToDateStorageKey: 'communication_page_to_date_data',
  communicationTabDropdownStorageKey: 'communication_page_tab_dropdown_data',
  communicationUserDropdownStorageKey: 'communication_page_user_dropdown_data',
  communicationTagDropdownStorageKey: 'communication_page_tag_dropdown_data',

  taskMyTaaskDropdownStorageKey: 'task_page_my_task_data',
  taskUserDropdownStorageKey: 'task_page_user_dropdown_data',
  taskCustomerDropdownStorageKey: 'task_page_customer_dropdown_data',
  taskOrgDefinedDropdownStorageKey: 'task_page_orgdefinedstatus_dropdown_data',

  activityUserDropdownStorageKey: 'activity_page_user_dropdown_data',
  activityMatterDropdownStorageKey: 'activity_page_matter_dropdown_data',
  activityCustomerDropdownStorageKey: 'activity_page_customer_dropdown_data',

  activityFromDateDataStorageKey: 'activity_from_date_data',
  activityToDateDataStorageKey: 'activity_to_date_data',
  organizationCodeStorageKey: 'organization_code_data',
  checkoutSessionDataStorageKey: 'checkout_session_data',
  googleClientId:
    '192653653608-tnfic7h7a4pu69a0h9cotc7ppkt72lit.apps.googleusercontent.com',
  userDataStorageKey: 'user_data',
  existingUserDataStorageKey: 'exiting_user_data',
  subscriptionQuantityStorageKey: 'sub_quantity_data',
  userPermissionStorageKey: 'user_permission_data',
  oneSignalAppId :'8d1cb604-8e92-428d-873f-a61a447a3a3b'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
