import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/utility/auth.interceptor';

// Custom imports
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { IconsProviderModule } from './icons-provider.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilityService } from './utility/utility.service';
import {
  NzNotificationModule,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './services/configuration.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgOtpInputModule } from 'ng-otp-input';
// import { AgmCoreModule } from '@agm/core';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgOtpInputModule,
    AppRoutingModule,
    NzModalModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NzNotificationModule,
    NgxStripeModule.forRoot(environment.stripe_public_key),
    // AgmCoreModule.forRoot({
    //   apiKey: ''
    // })
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    HttpService,
    AuthService,
    AuthGuard,
    UtilityService,
    ConfigurationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
