import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { timeout } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getToken();
    const authRequest: HttpRequest<any> = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    });
    // authRequest = authRequest.clone({ headers: new HttpHeaders({ timeout: `${3600000}` }) });
    return next.handle(authRequest).pipe(timeout(3600000));
  }
}
